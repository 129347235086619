// NB: to build a cordova app, relative path is needed here
const {
  HOME_PAGE_KEY,
  CONTACT_PAGE_KEY,
  PARTICIPANT_PAGE_KEY,
  USER_DATA_PAGE_KEY,
  MOBIGEO_PAGE_KEY,
  GOOGLE_MAP_PAGE_KEY,
  STORES_PAGE_KEY
} = require('../../src/pages/pagesKeys');
const dataConfig = require('./dataConfig');
const {
  TAIGA
} = require('../../src/core/webservices/WsProviders');
const projectConfig = require('../../../legacy-app-controller/config.js');
const {
  Online,
  Présentiel
} = require('./profiles');
const {
  DATA_TYPE_EVENT_CATEGORIES,
  DATA_TYPE_EVENTS
} = require('./dataConfig');
const codificationsConfig = require('./codificationsConfig');

// Only export a default object, to be able to
// override properties depending on target environment
const BO_TAIGA_URL = 'https://congres.experts-comptables.com';
const KLIPSO_LEADS_WS_ROOT = 'https://www.e-registration.net/WebServices/WS_Gaia_Mobile/GaiaMobile';
module.exports = {
  EVENT_NAME: 'AECS',
  // Misc UI
  DELAY_BEFORE_CLICK_ACTION: 50,
  // ms
  SHOW_TERMS_OF_USE_ON_HOME: true,
  // Terms of use URL
  TOU_LINK: {
    en: 'https://congres.experts-comptables.com/mobile/cgu.htm ',
    // TODO
    fr: 'https://congres.experts-comptables.com/mobile/cgu.htm ' // TODO
  },
  TOU_LINK_TARGET: '_blank',
  TOU_LINK_OPENININAPPBROWSER: true,
  // Badge URL
  BADGE_LINK: {
    en: '',
    // TODO
    fr: '' // TODO
  },
  BADGE_LINK_TARGET: '',
  BADGE_LINK_OPENININAPPBROWSER: false,
  // App profiles
  SUPPORTED_PROFILES: [Présentiel, Online],
  DEFAULT_PROFILE: null,
  //VISITOR_PROFILE,

  // Routing (in two columns mode, = default page on left column)
  getHomePage(profile) {
    switch (profile) {
      // case VISITOR_PROFILE:
      case Online:
      case Présentiel:
        return {
          pageKey: HOME_PAGE_KEY,
          props: null
        };

      /*
            case WEBAPP_EXH_PROFILE: return {
                pageKey: LIST_PAGE_KEY,
                props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EXHIBITOR_CATEGORIES }] },
            }
             case WEBAPP_EVT_PROFILE: return {
                pageKey: LIST_PAGE_KEY,
                props: { inputs: [{ dataType: dataConfig.DATA_TYPE_EVENT_CATEGORIES }] },
            }
             case WEBAPP_SPK_PROFILE: return {
                pageKey: LIST_PAGE_KEY,
                props: { inputs: [{ dataType: dataConfig.DATA_TYPE_SPEAKERS }]},
            }
            */

      default:
        console.error("Bad configuration, unexpected profile: ".concat(profile));
    }
  },
  APP_TITLE_SEPARATOR: ' - ',
  ROUTE_SEPARATOR: '?',
  // Lang
  SUPPORTED_LANGS: ['fr'],
  DEFAULT_LANG: 'fr',
  // Backend / Updates
  UPDATE_ENABLED: true,
  // Mobile-Spot link
  // MOBILESPOT_WWW: 'http://www.mobile-spot.com',

  // SHARE:
  DEFAULT_SHARE_IMAGE: '',
  //  on LinkedIn
  LINKEDIN_API_KEY: '',
  //  on Facebook
  FACEBOOK_APP_ID: '',
  WEB_URL: projectConfig.web.url,
  // In web mode, this fallback url can be used if the user's
  // browser does not meet some requirements (eg IE<11)
  WEB_FALLBACK: '',
  // e.g com.printemps.haussmann.dev
  NATIVE_ID: projectConfig.native.id,
  // Note: handling urls depending on environment is welcomed.
  // in that case, declare this variable in config-[env].js
  ADDITIONAL_DEFAULTSRC_CSP: [KLIPSO_LEADS_WS_ROOT, BO_TAIGA_URL],
  /**
   * FEATURES
   */

  DOWNLOAD_FAVORITES: {
    FEATURE_ENABLED: false
  },
  APPOINTMENT_REQUEST: {
    FEATURE_ENABLED: false
    // webservice url is defined per environment
  },
  CONTRIBUTIONS_FEED: {
    FEATURE_ENABLED: false
  },
  FLIGHTS_SCHEDULE: {
    FEATURE_ENABLED: false
  },
  LOGIN: {
    FEATURE_ENABLED: true,
    displayLabel: true,
    PROVIDER: TAIGA,
    forgottenPasswordURL: {
      fr: "https://congres.experts-comptables.com/espace-exposant/authentification/acceder-a-mon-espace-exposant/mot-de-passe-oublie.htm"
      //en: `${BO_TAIGA_URL}/en/mobile/forgot-your-password.htm`,
    },
    forgottenPasswordURLTarget: '_blank',
    forgottenPasswordOpenInInAppBrowser: true,
    createAccountURL: null,
    createAccountURLTarget: '_blank',
    createAccountOpenInInAppBrowser: true,
    tosURL: null,
    tosURLTarget: '_blank'
  },
  BO_TAIGA_WS_URL: "".concat(BO_TAIGA_URL, "/tgServices/mobile/aecs"),
  POLL: {
    FEATURE_ENABLED: false
  },
  USER_DATA: {
    FEATURE_ENABLED: true,
    PROVIDER: TAIGA,
    SECTIONS: [{
      className: '',
      label: '',
      displayLabel: true,
      fields: {
        profilePicture: {
          type: 'image',
          editable: true
        },
        firstName: {
          type: 'text',
          required: true,
          editable: false
        },
        lastName: {
          type: 'text',
          required: true,
          editable: false
        },
        organizationName: {
          type: 'text',
          editable: false
        },
        position: {
          type: 'text',
          required: true,
          editable: false
        },
        login: {
          type: 'text',
          required: true,
          editable: false
        },
        email: {
          type: 'email',
          required: true,
          pattern: /^[\w._+-]+@[\w.-]+\.[A-Za-z]{2,64}$/,
          editable: false
        }
      }
    }
    /*     {
      className: 'ud-section-switches',
      label: 'userData.notice', // i18n path
      fields: {
        contactable: {
          type: 'bool',
          required: true,
          editable: true,
        },
      },
    }, */],
    externalResourceLinkTarget: '_blank',
    externalResourceLinkOpenInInAppBrowser: true
  },
  IMAGE_UPLOAD: {
    // see https://github.com/blueimp/JavaScript-Load-Image#options
    LOAD_IMAGE_OPTIONS: {
      maxWidth: 900,
      maxHeight: 900,
      contain: false,
      orientation: true,
      canvas: true
    },
    INPUT_ACCEPTED_MIME: 'image/*',
    OUTPUT_MIME: 'image/jpeg',
    OUTPUT_COMPRESSION: 0.92,
    DISPLAY_IMAGE_OPTIONS: {
      maxWidth: 300,
      maxHeight: 300,
      cropMinWidth: 100,
      cropMinHeight: 100,
      cropInitialWidth: 300,
      cropRatio: 0.85
    }
  },
  KLIPSO_LEADS: {
    FEATURE_ENABLED: false,
    hasklipsoLeadsButtonInToolbar: false,
    HAS_HEADER_CLOSE_BUTTON: true,
    LABEL_INSTEAD_OF_ICON: true,
    LICENCE_SCAN_ACCEPTED_FORMATS: 'QR_CODE',
    LICENCE_REGEXP: /^[A-Za-z0-9]{10}$/,
    TOS_LINKS: {
      fr: 'https://badge.maison-objet.com/mobile/lecteurs-de-badges.htm',
      en: 'https://badge.maison-objet.com/en/mobile/badges-reader.htm'
    },
    CHECK_LICENCE_WS_URL: "".concat(KLIPSO_LEADS_WS_ROOT, "/AuthenticateExhibitorAppByCAB"),
    REGISTER_CHECKPOINT_WS_URL: "".concat(KLIPSO_LEADS_WS_ROOT, "/RegisterCheckPoint"),
    GET_FORM_FIELDS_WS_URL: "".concat(KLIPSO_LEADS_WS_ROOT, "/GetFormFields"),
    SAVE_WS_URL: "".concat(KLIPSO_LEADS_WS_ROOT, "/SaveVisitorRelationList")
  },
  NETWORKING: {
    FEATURE_ENABLED: false,
    PROVIDER: TAIGA,
    PARTICIPANTS_DATA_MODE: 'PARTIAL',
    // 'ALL' or 'PARTIAL' - see SAN-40
    ALL_PARTICIPANTS_DATA_EXPIRE_DELAY: 1 * 60 * 1000,
    FETCH_EXHIBITOR_PARTICIPANTS: true,
    FETCH_SPEAKER_PARTICIPANT: true
  },
  SEARCH_TAIGA: {
    WS_PARAMS: {
      start: 0,
      take: 100
    },
    SIMPLE_SECTIONS: [{
      className: '',
      label: '',
      displayLabelInput: true,
      fields: {
        keyword: {
          type: 'text',
          label: 'searchTaiga.fields.search.label',
          placeholder: 'searchTaiga.fields.search.placeholder',
          hasSearchButton: true
        }
      }
      /*buttons: [
        {
          key: 'simple-search-toggle-btn', // mandatory
          type: 'TOGGLE',
          defaultVisibleButtonIndex: 0, // optional (default: 0)
          content: [
            {
              label: 'searchTaiga.fieldToggle.byCompany',
              field: {
                organizationName: {
                  type: 'text',
                  label: 'searchTaiga.fields.company.label',
                  placeholder: 'searchTaiga.fields.company.placeholder',
                  hasSearchButton: true,
                },
              },
            },
            {
              label: 'searchTaiga.fieldToggle.byName',
              field: {
                lastName: {
                  type: 'text',
                  placeholder: 'searchTaiga.fields.name.placeholder',
                  label: 'searchTaiga.fields.name.label',
                  hasSearchButton: true,
                },
              },
            },
          ],
        },
      ],*/
    }],
    ADVANCED_SECTIONS: [{
      className: '',
      label: '',
      displayLabelInput: true,
      displayIcon: false,
      // displayIcon for displaying icon on form input
      fields: {
        lastName: {
          type: 'text-modal',
          label: 'searchTaiga.fields.name.label',
          placeholder: 'searchTaiga.fields.name.placeholder',
          icon: 'user-circle-icon'
        },
        organizationName: {
          type: 'text-modal',
          label: 'searchTaiga.fields.company.label',
          placeholder: 'searchTaiga.fields.company.placeholder',
          icon: 'tag-icon icon-flip-horizontal' // displayIcon:true for displaying this icon
        },
        role: {
          type: 'checkbox-modal',
          multiple: false,
          label: 'searchTaiga.fields.role.label',
          placeholder: 'searchTaiga.fields.role.placeholder',
          icon: 'cog-icon',
          // TODO
          values: {
            codif: codificationsConfig.CODIFICATION_FONCTION
          }
        },
        profil: {
          type: 'checkbox-modal',
          multiple: false,
          label: 'searchTaiga.fields.profil.label',
          placeholder: 'searchTaiga.fields.profil.placeholder',
          icon: 'cog-icon',
          // TODO
          values: {
            codif: codificationsConfig.CODIFICATION_PROFIL
          }
        },
        service: {
          type: 'checkbox-modal',
          multiple: false,
          label: 'searchTaiga.fields.service.label',
          placeholder: 'searchTaiga.fields.service.placeholder',
          icon: 'cog-icon',
          // TODO
          values: {
            codif: codificationsConfig.CODIFICATION_SERVICE
          }
        },
        centerinterest: {
          type: 'checkbox-modal',
          multiple: true,
          label: 'searchTaiga.fields.centerinterest.label',
          placeholder: 'searchTaiga.fields.centerinterest.placeholder',
          icon: 'cog-icon',
          // TODO
          values: {
            codif: codificationsConfig.CODIFICATION_CENTREINTERET
          }
        }
      }
    }]
  },
  SHARE: {
    FEATURE_ENABLED: true,
    POSITION_SHARE_IMAGE: '',
    // Declare restrictions
    // If current page + current profile are found below,
    // then share button is hidden.
    RESTRICTIONS: [
      // e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  INBOX: {
    FEATURE_ENABLED: true,
    hasInboxButtonInToolbar: false,
    hasInboxButtonInMenu: false,
    RESTRICTIONS: [
      // e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  NOTES: {
    FEATURE_ENABLED: true,
    EXPORT_NOTES: true,
    hasNotesButtonInToolbar: false,
    RESTRICTIONS: [
      // e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  CONTACTS: {
    FEATURE_ENABLED: false,
    hasContactsButtonInToolbar: false,
    RESTRICTIONS: [],
    SAVE_CONTACT_TO_DEVICE: {
      FEATURE_ENABLED: false,
      RESTRICTIONS: []
    }
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    HasBtnSwitchFavSynchMode: false,
    HasLabelSwitchFavSynchMode: false,
    PUBLIC_KEY: 'eN/sCwOHNN+42fjwDoLj35xaVN0OgnXsxzV8+yvu0oy0Ea3Klk19j3EEN3uPZkw3HR8HlEpOSg999rsBwwklDv/BoRNt8RhYZoCyDfmcfhusV/w8go7jtw6JI4q4P8nfB6QgotAfpAYG/ijCOzbbXI/WG1uppA2DP8R6vb7d7WM=',
    UNAUTHORIZED_MODE_ENABLED: true,
    // webservice url is defined per environment

    MODE: 'peering',
    // peering or login
    // Debouncing value represents the delay between
    // last favorite toggle (add or remove) and server call
    DEBOUNCING: 10 * 1000 // ms
  },
  SOCIAL: {
    FEATURE_ENABLED: false,
    hasSocialButtonInToolbar: false,
    TEST_FEATURE_ENABLED: false
  },
  SYNOPTIC_AGENDA: {
    AUTOFOCUS_TAB: true,
    SYNOPTIC_DEFAULT_EVENT_BGCOLOR: '#404040'
  },
  MODAL_PRIVACY_AGREEMENT: {
    ENABLED_PLATFORM_FEATURE: []
  },
  EXPORT_EVENT: {
    FEATURE_ENABLED: false
  },
  MAP: {
    DEFAULT_MAP_PAGE_KEY: MOBIGEO_PAGE_KEY,
    // default map page key, when there is only one to be choosed like for the feature TwoColumn ( GOOGLE_MAP_PAGE_KEY OR MOBIGEO_PAGE_KEY )
    MOBIGEO: {
      FEATURE_ENABLED: true,
      API_KEY: 'ooPith6toR',
      // need to be the same as in data/config.default.js => data.default
      LOG_LEVEL: 'NONE',
      // accepted values: NONE | INFO | DEBUG
      USED_TABLES: [
      // DATA_TYPE_AIRCRAFTS,
      // DATA_TYPE_BRANDS,
      dataConfig.DATA_TYPE_STORES, dataConfig.DATA_TYPE_EVENTS, dataConfig.DATA_TYPE_EXHIBITORS, dataConfig.DATA_TYPE_ICONS, dataConfig.DATA_TYPE_PLACES,
      // DATA_TYPE_RESTAURANTS,
      dataConfig.DATA_TYPE_SERVICES,
      // DATA_TYPE_ANIMATIONS
      dataConfig.DATA_TYPE_HAPPENINGS
      // also: gtw_paths, paths, polygons, but they are in graph.json
      // which is an asset, updated by complete overriding.
      ],
      AUTO_RELOAD_AT_UPDATE: true
    },
    GOOGLE_MAP: {
      FEATURE_ENABLED: false,
      HAS_GMAP_FILTER_BY_CATEGORIES: false,
      HAS_LOCATION_BUTTON: false,
      DEFAULT_LOCATION_IMAGE: 'icons-svg/location_button.png',
      API_KEY: 'AIzaSyA9LJKlxiAIr-DErCo1xaMTHqZ7WWH96QM',
      USED_TABLE: dataConfig.DATA_TYPE_STORE_CATEGORIES,
      PAGE_KEY_RELATED_TO_USED_TABLE: STORES_PAGE_KEY,
      USED_TABLES: [dataConfig.DATA_TYPE_STORE_CATEGORIES, dataConfig.DATA_TYPE_STORES, dataConfig.DATA_TYPE_GMAP_PLACES],
      AUTO_RELOAD_AT_UPDATE: true
    }
  },
  OAuth2: {
    FEATURE_ENABLED: false,
    providerID: 'YDVBEEXY',
    // "B-Com",
    response_type: 'code',
    // code or token(default)
    authorization_url: 'https://www.eventime.fr/OAuth',
    logout_url: 'https://www.eventime.fr/Account/Logout.aspx',
    client_id: 'Mob1le5p0t_SOFCOT21',
    eventcode: 'SOFCOT2021',
    redirect_uri: 'http://localhost:3000',
    // https://sofcot-dev.mobile-spot.com',
    client_secret: '2064FF307B2E33C0C5A9ABE527DD3E9ABF7D6EB0',
    WEB_LINK_TARGET: '_self',
    community_code: '',
    /* Username : 'testoauth.mobilespot1',
    Password : 'zlrl545&', */
    userData(user) {
      return {
        /* profilePicture: user.photo ? user.photo : '',
            title: user.title ?  user.title : '',
             position: user.position ?  user.position : '',
            login: user.login ?  user.login : '',
            phone: user.phone ?  user.phone : '', */
        id: user.id ? user.id : '',
        firstName: user.first_name ? user.first_name : '',
        lastName: user.last_name ? user.last_name : '',
        isViewer: user.isViewer ? user.isViewer : false,
        email: user.email ? user.email : '',
        participantEventCodes: user.participant_event_codes ? user.participant_event_codes : '',
        memberCommunityCodes: user.member_community_codes ? user.member_community_codes : ''
      };
    }
  },
  PUSHWOOSH: {
    FEATURE_ENABLED: true,
    ACTIVATE_TEST_DEVICES: true
  },
  GEOLOCATION: {
    FEATURE_ENABLED: false,
    BLUETOOTH_CAPABILITY_ENABLED: false,
    LOCATION_ENABLED: false
  },
  LOTTIE_SPLASHSCREEN: {
    FEATURE_ENABLED: false
  },
  SHOW_PDF_ANDROID: {
    FEATURE_ENABLED: false
  },
  SECURED_PDF: {
    FEATURE_ENABLED: false
  },
  HELPERS: {
    FEATURE_ENABLED: false
  },
  TWO_COLUMNS_MODE_MANAGER: {
    RESTRICTIONS: []
  },
  AGENDA_AS_PDF: {
    FEATURE_ENABLED: false,
    WEB_ONLY: true
  }
};