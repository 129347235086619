import {
// DATA_TYPE_ANIMATIONS,
DATA_TYPE_EVENTS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_SPEAKERS,
// DATA_TYPE_PAPERS,
DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_STORE_CATEGORIES,
// DATA_TYPE_PAPER_CATEGORIES,
// DATA_TYPE_AIRCRAFTS,
// DATA_TYPE_AIRCRAFT_CATEGORIES,
DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES,
// DATA_TYPE_DOCUNITS,
DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTED_PARTICIPANTS, DATA_TYPE_CONTACTS, DATA_TYPE_STORES, DATA_TYPE_KLIPSO_LEADS_CONTACTS, getSpeakerTitle } from 'app-customs/config/dataConfig';
import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';
import { SEARCH_TYPES } from 'src/core/search/Search';

// Perform search when user has typed at least n characters
export const SEARCH_MIN_CHARS = 2;

// export const SEARCH_HIDE_LIST_IF_TOTAL_ABOVE = 10;

/**
 * Data types to look through to find POI on map or itinerary start/destination
 * @type {Array}
 */
export const DATATYPES_WITH_PLACES = [
// DATA_TYPE_AIRCRAFTS,
// DATA_TYPE_ANIMATIONS,
// DATA_TYPE_EVENTS,
DATA_TYPE_EXHIBITORS, DATA_TYPE_STORES, DATA_TYPE_SERVICES];
export function getSearchType(pageKey, value) {
  if (pageKey !== SEARCH_PAGE_KEY) {
    if (value.length === 1) {
      return SEARCH_TYPES.STARTS_WITH;
    }
    if (value.length === 2) {
      return SEARCH_TYPES.WORD_STARTS_WITH;
    }
  }
}

// Common 'title'
const getTitleAttribute = item => ({
  text: item.title
});
const getExhibitorAttributes = item => {
  const fields = {
    text: item.title
  };
  if (item.description) {
    fields.keywords = [item.description];
  }
  if (item.lump.catTag && item.lump.catTag.length > 0) {
    fields.keywords = fields.keywords ? fields.keywords.concat(item.lump.catTag) : item.lump.catTag;
  }
  return fields;
};
const getPaperAttributes = item => {
  const fields = {
    text: item.title
  };
  if (item.lump.authors) {
    fields.text += " ".concat(item.lump.authors);
  }
  return fields;
};
const getSpeakerAttributes = item => {
  const fields = {
    text: getSpeakerTitle(item)
  };
  if (item.organisation) {
    fields.text += " ".concat(item.organisation);
  }
  return fields;
};
const getEventAttributes = item => {
  const fields = {
    text: item.title
  };
  if (item.lump && item.lump.keywords) {
    fields.text += " ".concat(item.lump.keywords);
  }
  return fields;
};

/**
 * @return {Object} config where:
 *   -key: datatype to include in search scope
 *   -value: function returning the string on which the search is applied
 */
export function get(profile, pageKey) {
  const config = {
    // [ DATA_TYPE_AIRCRAFTS ]: getTitleAttribute,
    // [ DATA_TYPE_AIRCRAFT_CATEGORIES ]: getTitleAttribute,
    [DATA_TYPE_EXHIBITORS]: getExhibitorAttributes,
    [DATA_TYPE_EXHIBITOR_CATEGORIES]: getTitleAttribute,
    [DATA_TYPE_STORE_CATEGORIES]: getTitleAttribute,
    [DATA_TYPE_STORES]: getTitleAttribute,
    // [DATA_TYPE_NEWPRODUCTS]: getTitleAttribute,
    // [DATA_TYPE_NEWPRODUCT_CATEGORIES]: getTitleAttribute,
    [DATA_TYPE_EVENTS]: getEventAttributes,
    [DATA_TYPE_EVENT_CATEGORIES]: getTitleAttribute,
    // [DATA_TYPE_SPEAKERS]: getSpeakerAttributes,
    // [ DATA_TYPE_PAPERS ]: getPaperAttributes,
    // [ DATA_TYPE_PAPER_CATEGORIES ]: getTitleAttribute,
    [DATA_TYPE_SERVICES]: getTitleAttribute,
    [DATA_TYPE_SERVICE_CATEGORIES]: getTitleAttribute
    // [ DATA_TYPE_ANIMATIONS ]: getTitleAttribute,
  };
  if (pageKey !== SEARCH_PAGE_KEY) {
    // These data type are in the scope of contextual searches only:

    config[DATA_TYPE_PARTICIPANTS] = item => "".concat(item.lastName || '', " ").concat(item.role || '', " ").concat(item.organizationName || '', " ").concat(item.type || '');
    config[DATA_TYPE_CONTACTED_PARTICIPANTS] = item => "".concat(item.lastName || '', " ").concat(item.role || '', " ").concat(item.organizationName || '', " ").concat(item.type || '');
    config[DATA_TYPE_CONTACTS] = item => "".concat(item.firstName || '', " ").concat(item.lastName || '', " ").concat(item.role || '', " ").concat(item.organizationName || '');
    config[DATA_TYPE_KLIPSO_LEADS_CONTACTS] = item => "".concat(item.Prenom || '', " ").concat(item.Nom || '', " ").concat(item.Societe || '', " ").concat(item.Email || '');
  }

  // if (profile === 'presse') {
  //     config[ DATA_TYPE_DOCUNITS ] = getTitleAttribute
  // }

  return config;
}