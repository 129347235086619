import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES,
//DATA_TYPE_EXHIBITORS,
//DATA_TYPE_EVENTS,
DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS,
//DATA_TYPE_SERVICES,
DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_SPEAKER_CATEGORIES, DATA_TYPE_STORE_CATEGORIES } from 'app-customs/config/dataConfig';
import { createItem,
//createItemLang,
createItemRoute, createItemToggleLocation, createItemTogglePMR } from 'src/core/util/ConfigItemFactory';
/* 
import * as Db from 'src/core/data-and-assets/Db';
import { startUpdate } from 'src/core/data-and-assets/Updater'; */
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { /* isIOS, isAndroid, */isCordovaContext, isMobile } from 'src/core/util/browser';
import { HOME_PAGE_KEY, FAVORITES_PAGE_KEY, INBOX_PAGE_KEY, KLIPSO_LEADS_PAGE_KEY, LIST_PAGE_KEY, MOBIGEO_PAGE_KEY, SEARCH_PAGE_KEY, MEDIAS_PAGE_KEY, CHOOSE_PROFILE_PAGE_KEY, LIST_GROUPS_PAGE_KEY, NOTES_PAGE_KEY, USER_DATA_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY, GOOGLE_MAP_PAGE_KEY, LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';
import KLipsoIcon from 'app-customs/public/icons-svg/KlipsoLead.svg';
import badgeIcon from 'app-customs/public/icons-svg/badge.svg';
import { openUrl } from 'src/core/util/JsTools';
import config from './config';
import * as Query from 'src/core/query/Query';
import { get as getLabels, getCurrent as getLang } from 'src/core/Lang';
import { CONFERENCES_TAB_KEY } from 'src/pages/user-data/UserDataContent';
import { isSessionValid } from 'src/core/login/LoginService';

//const { DEFAULT_PROFILE } = require('./profiles');
const {
  Présentiel,
  Online
} = require('./profiles');
const LOG_PREF = '[menuConfig] ';

// documentation: https://material-ui.com/api/swipeable-drawer/#props
export const MENU_OPTIONS = {
  anchor: 'right',
  swipeAreaWidth: isMobile() ? 10 : 0 // default 20
};

// see https://github.com/markusenglund/react-switch#api
export const LOCATION_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
export const PMR_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
const Small_SVG_SIZE = '2.2em';
const MENU_ICON_COLOR = '000000';
const getServiceIdByLumpOriginalId = (original_id, dataType) => {
  const items = Query.getTopCategories(dataType);
  let parentId;
  items && items.map(item => {
    if (item.original_id === original_id) {
      parentId = item.id;
    }
  });
  return parentId;
};
const getServiceIdByLumpMainId = (main_id, dataType) => {
  const items = Query.getTopCategories(dataType);
  let parentId;
  items && items.map(item => {
    if (item.lump.main_id === main_id) {
      parentId = item.id;
    }
  });
  return parentId;
};

/**
 * Generate a menu entry to route to Home page
 */
function getItemRouteHome(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_accueil.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.home.title,
  // page key:
  HOME_PAGE_KEY);
}

/**
 * Generate a menu entry to route to list of Innovations
 */
function getItemRouteNewProduct(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/Innovations.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.newproducts,
  // page key:
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  });
}

/**
 * Generate a menu entry to route to list of Stores
 */
function getItemRouteStores(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/participants.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.stores,
  // page key:
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_STORE_CATEGORIES
    }]
  });
}

/**
 * Generate a menu entry to route to the list of exhibitors
 */
function getItemRouteExhibitors(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_exposants.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.exhibitors,
  // page key:
  LIST_PAGE_KEY, {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
    }]
  });
}

/**
 * Generate a menu entry to route to the quiz
 */
function getItemRouteQuiz(labels, actions) {
  return createItem(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_quiz_en_ligne.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.quiz,
  // page key:
  () => {
    const url = 'https://files.youslide.io/ecs/index.html';
    if (url) openUrl(url, next => next && actions.linkClicked(url, labels.menu.quiz), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
  });
}

/**
 * Generate a menu entry to route to the malette
 */
function getItemRouteMalette(labels, actions) {
  return createItem(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_malette_dematérialisee.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.malette,
  // page key:
  () => {
    const url = 'https://congres.experts-comptables.com/exposition/recherche-partenaires.htm?Crit_RechExposant=x_ServicePlus%3ASP01';
    if (url
    // eslint-disable-next-line no-empty
    ) {
      openUrl(url, next => next && actions.linkClicked(url, labels.menu.malette), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
    }
  });
}

/**
 * Generate a menu entry to route to the demo
 */
function getItemRouteDemo(labels, actions) {
  return createItem(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_parcours_demo.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.demo,
  // page key:
  () => {
    const url = 'https://congres.experts-comptables.com/exposition/recherche-partenaires.htm?Crit_RechExposant=x_ServicePlus%3ASP02';
    if (url) {
      openUrl(url, next => next && actions.linkClicked(url, labels.menu.demo), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
    }
  });
}

/**
 * Generate a menu entry to route to the covoiturage
 */
function getItemRouteCovoiturage(labels, actions) {
  return createItem(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_covoiturage.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.covoiturage,
  // page key:
  () => {
    const url = 'https://congres.experts-comptables.com/informations-pratiques-rse/covoiturage.htm';
    if (url) {
      openUrl(url, next => next && actions.linkClicked(url, labels.menu.covoiturage), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
    }
  });
}

/**
 * Generate a menu entry to route to the list of services
 */
function getItemRouteServices(labels, actions) {
  return createItem(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_infos_pratiques.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.services,
  // page key:
  () => {
    const url = 'https://congres.experts-comptables.com/informations-pratiques-rse/informations-pratiques.htm';
    if (url) {
      openUrl(url, next => next && actions.linkClicked(url, labels.menu.services), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
    }
  });
}

/**
 * Generate a menu entry to route to the list of services
 */
function getItemRouteNews(labels, actions) {
  return createItem(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_site_du_congres.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.news,
  // page key:
  () => {
    const url = 'https://congres.experts-comptables.com';
    if (url) {
      openUrl(url, next => next && actions.linkClicked(url, labels.menu.news), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
    }
  });
}
function getItemRouteConf(labels, actions) {
  if (config.LOGIN.FEATURE_ENABLED !== true) {
    return null;
  }
  return createItem(
  // Style
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_conferences.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.conf,
  // Action to perform on click
  () => {
    if (!isSessionValid()) {
      actions.navigate(LOGIN_PAGE_KEY);
    } else {
      actions.navigate(USER_DATA_PAGE_KEY, {
        tabKey: CONFERENCES_TAB_KEY,
        tabIndex: undefined
      });
    }
  });
}

/**
 * Generate a menu entry to route to participants
 */
function getItemRouteParticipants(labels) {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }
  const isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/Mise-en-relation.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.miseEnRelation,
  // page key:
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY,
  // page props:
  isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  });
}

/**
 * Generate a menu entry to route to list of event categories
 */
function getItemRouteAgenda(labels, profile) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_programme.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.agenda,
  // page key:
  LIST_PAGE_KEY, {
    inputs: [{
      id: getServiceIdByLumpOriginalId(profile === Présentiel ? '_BY_ONSITE_' : profile === Online && '_BY_ONLINE_', DATA_TYPE_EVENT_CATEGORIES),
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }],
    contextualTitle: getLabels() && getLabels().data[DATA_TYPE_EVENT_CATEGORIES].title,
    hasGoToSynopticButton: true
  });
}

/**
 * Generate a menu entry to route to the list of speakers
 */
function getItemRouteSpeakers(labels, profile) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_intervenants.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.speakers,
  // page key:
  LIST_PAGE_KEY,
  // page props:
  {
    inputs: [{
      id: getServiceIdByLumpOriginalId(profile === Présentiel ? '_BY_ONSITE_' : profile === Online && '_BY_ONLINE_', DATA_TYPE_SPEAKER_CATEGORIES),
      dataType: DATA_TYPE_SPEAKER_CATEGORIES
    }],
    contextualTitle: getLabels() && getLabels().data[DATA_TYPE_SPEAKER_CATEGORIES].title
  });
}

/**
 * Generate a menu entry to route to the list of papers categories
 */
function getItemRoutePapers(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Abstracts.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.papers,
  // page key:
  LIST_PAGE_KEY,
  // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_PAPER_CATEGORIES
    }]
  });
}

/**
 * Generate a menu entry to route to Mobigeo page
 */
function getItemRouteMobigeo(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_plan.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.mobigeo,
  // page key:
  MOBIGEO_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Google Map page
 */
function getItemRouteGoogleMap(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/planParis.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.googleMap,
  // page key:
  GOOGLE_MAP_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Notes page
 */
function getItemRouteNotes(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_notes.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.notes.pageLinkTitle,
  // page key:
  NOTES_PAGE_KEY);
}

/**
 * Generate a menu entry to route to global Search page
 */
function getItemRouteSearch(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_recherche.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.search.title,
  // page key:
  SEARCH_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Favorites page
 */
function getItemRouteFavorites(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_favoris.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.favorites.title,
  // page key:
  FAVORITES_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Inbox page
 */
function getItemRouteInbox(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_message.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.inbox,
  // page key:
  INBOX_PAGE_KEY);
}

/**
 * Generate a menu entry to route to Klipso Leads page
 */
function getItemRouteKlipsoLeads(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(\"".concat(KLipsoIcon.replace("fill='black'", "fill='%23".concat(MENU_ICON_COLOR, "'")), "\")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.klipsoLeads,
  // page key:
  KLIPSO_LEADS_PAGE_KEY);
}
function getItemRouteBadge(labels) {
  return createItem(
  // Style
  {
    icon: {
      style: {
        backgroundImage: "url(\"".concat(badgeIcon.replace("fill='black'", "fill='%23".concat(MENU_ICON_COLOR, "'")), "\")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // Label (i18n)
  labels.menu.ebadge,
  // Action to perform on click
  () => {
    if (openUrl(config.BADGE_LINK[getLang()], null, config.BADGE_LINK_TARGET, config.BADGE_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.BADGE_LINK[getLang()]);
    }
  });
}

/**
 * Generate a menu entry to change app language
 */
function getItemLang(labels, actions) {
  return createItem(
  // style:
  {
    icon: {
      svgProps: {
        src: 'icons-svg/langue.svg'
      },
      style: {
        /* backgroundImage: `url(${getUrl('icons-svg/fiche/globe-solid.svg')})`,
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center', */
        width: Small_SVG_SIZE,
        height: Small_SVG_SIZE,
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.language,
  // function to execute:
  actions.showChooseLangDialog);
}

/**
 * Generate a menu entry to open TOU link
 */
const getActionTOU = (labels, actions) => createItem(
// style:
{
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/menu/picto_CGU.svg'), ")"),
      backgroundSize: Small_SVG_SIZE,
      backgroundPosition: 'center',
      lineHeight: '1.4em'
    }
  }
},
// label:
labels.menu.cgu,
// function to execute:
() => {
  if (openUrl(config.TOU_LINK[labels.id], next => next && actions.linkClicked(config.TOU_LINK[labels.id], labels.menu.cgu), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {}
});

/**
 * Generate a menu entry to open TOU link
 */
const getActionBadge = labels => createItem(
// style:
{
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/badge.svg'), ")"),
      backgroundSize: Small_SVG_SIZE,
      backgroundPosition: 'center',
      lineHeight: '1.4em'
    }
  }
},
// label:
labels.menu.ebadge,
// page key:
KLIPSO_LEADS_PAGE_KEY,
// page props:
null);
const getUserData = labels => createItemRoute(
// style:
{
  icon: {
    //className: 'user-icon',

    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/picto_profil.svg'), ")"),
      backgroundSize: Small_SVG_SIZE,
      backgroundPosition: 'center',
      lineHeight: '1.4em'
    }
  }
},
// label:
labels.menu.userData,
// page key:
USER_DATA_PAGE_KEY,
// page props:
null);

/**
 * Generate a menu entry to route to Choose profile page
 */
function getItemRouteChooseProfile(labels, profile) {
  return createItemRoute(
  // style:
  {
    icon: {
      //className: 'user-icon',

      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_profil.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  (profile ? "<div class=\"menu-text-current-profile\">".concat(profile, "</div>") : '') + labels.menu.toggleProfile,
  // page key:
  CHOOSE_PROFILE_PAGE_KEY,
  // Page props:
  null);
}

/**
 * Generate a menu entry using configuration from a config.json menubuttons item
 */
function getAdMenuButton(conf, labels, actions) {
  if (!conf) {
    return null;
  }
  return createItem(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(conf["img_".concat(labels.id)]), ")"),
        backgroundSize: Small_SVG_SIZE
      }
    }
  },
  // label:
  conf["label_".concat(labels.id)],
  // function to execute:
  () => {
    const url = conf["link_".concat(labels.id)];
    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  });
}

/**
 * Generate a menu entry to route to Social Media page
 */
function getItemSocialMedia(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/picto_reseaux_sociaux.svg'), ")"),
        backgroundSize: Small_SVG_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.medias,
  // page key:
  MEDIAS_PAGE_KEY,
  // page props:
  {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'twitter'
    }
  });
}

/**
 * Generate a menu entry to route to Video page
 */
function getItemVideos(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/Youtube.svg'), ")"),
        backgroundSize: Small_SVG_SIZE
      }
    }
  },
  // label:
  labels.menu.videos,
  // page key:
  MEDIAS_PAGE_KEY,
  // page props:
  {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'youtube'
    }
  });
}

/**
 * Generate a button to toggle location
 */
function getItemToggleLocation(labels) {
  return createItemToggleLocation(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/location.svg'), ")"),
        backgroundSize: Small_SVG_SIZE
      }
    }
  },
  // label:
  labels.menu.location);
}

/**
 * Generate a button to toggle PMR status (used for mobigeo routing)
 */
export function getItemTogglePMR(labels) {
  return createItemTogglePMR(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/menu/pmr.svg'), ")"),
        backgroundSize: Small_SVG_SIZE
      }
    }
  },
  // label:
  labels.menu.pmr);
}

/**
 * Generate an item to open the current page in the mobile app
 * for deeplinking test purpose
 */
/* function getItemOpenCurrentPageInNativeApp() {
    return createItem(
        // style
        { icon: {
            className: 'fab fa-'+(isIOS() ? 'apple' : 'android'),
            style: {
                color: '#bababa',
                fontSize: '2em',
            }
        }},
        // label:
        'Open page in '+(isIOS() ? 'iOS' : 'android')+' app',
        // function to execute:
        openCurrentPageInApp,
    );
} */

/**
 * Menu configuration
 */
// const getConfig = (actions, labels, adConfig, twoColumns) => {
//     let conf = {
//         default: [
//             getItemRouteBrands(labels),
//             getItemRouteRestaurants(labels),
//             getItemRouteServices(labels),
//             getItemRouteHours(labels),
//             getItemRouteMobigeo(labels),
//             // Generic:
//             getItemRouteSearch(labels),
//             getItemRouteFavorites(labels),
//             getItemRouteInbox(labels),
//             getItemLang(labels, actions),
//             config.ENV === 'dev' && global.isCordovaContext !== true ? getItemOpenCurrentPageInNativeApp() : null,
//         ]
//     };
//
//     if (adConfig && adConfig.menubuttons) {
//         Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
//             conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions) );
//         });
//     }
//
//     // Filter any empty entry
//     Object.keys(conf).forEach(function(profile) {
//         conf[profile] = conf[profile].filter(menuItemConfig => menuItemConfig);
//     });
//
//     return conf;
// };

/**
 * Menu configuration for default profile
 */
const getDefaultConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [getItemRouteHome(labels), getItemRouteAgenda(labels, profile), getItemRouteExhibitors(labels), getItemRouteMobigeo(labels), getItemRouteConf(labels, actions), getItemRouteSpeakers(labels, profile),
    // QUIZ EN LIGNE
    getItemRouteQuiz(labels, actions),
    // MALETTE DEMATERIALISEE
    getItemRouteMalette(labels, actions),
    // PARCOURS DEMO
    getItemRouteDemo(labels, actions),
    // COVOITURAGE
    getItemRouteCovoiturage(labels, actions), getItemRouteServices(labels, actions), getItemSocialMedia(labels), getItemRouteNews(labels, actions),
    //getItemRouteBadge(labels),
    //getItemRouteStores(labels),
    //isCordovaContext() && getItemRouteGoogleMap(labels),
    getItemRouteFavorites(labels), config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null, getItemRouteSearch(labels),
    //getItemRouteKlipsoLeads(labels),
    //getItemLang(labels, actions),
    getItemRouteInbox(labels),
    // Changer profile
    getItemRouteChooseProfile(labels, labels.profile[profile])]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  conf.default.push(getActionTOU(labels, actions));
  return conf;
};

/**
 * Menu configuration for GP profile
 */
const getWebAppExhConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [getItemRouteFavorites(labels), getItemRouteNotes(labels), getItemRouteExhibitors(labels), getItemRouteMobigeo(labels)
    // getItemLang(labels, actions),
    // getItemSocialMedia(labels),
    ]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};

/**
 * Menu configuration for PRESS profile
 */
const getWebAppEvtConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [getItemRouteFavorites(labels), getItemRouteAgenda(labels, profile), getItemRouteSpeakers(labels, profile)
    // getItemRouteMobigeo(labels),
    ]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};

/**
 * Menu configuration for PRESS profile
 */
const getWebAppSpkConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [getItemRouteFavorites(labels), getItemRouteSpeakers(labels, profile), getItemRouteAgenda(labels, profile)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};

/**
 * Return menu configuration
 * @param  {string} profile
 * @return {object}
 */
export const get = function (profile) {
  let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  let actions = arguments.length > 2 ? arguments[2] : undefined;
  let labels = arguments.length > 3 ? arguments[3] : undefined;
  let adConfig = arguments.length > 4 ? arguments[4] : undefined;
  let twoColumns = arguments.length > 5 ? arguments[5] : undefined;
  let conf;
  // Get menu config depending on user profile
  switch (profile) {
    case Présentiel:
    case Online:
      conf = getDefaultConfig(actions, labels, adConfig, profile);
      break;
    // case EXHIBITOR_PROFILE:
    //   conf = getDefaultConfig(actions, labels, adConfig, labels.profile[profile]);
    //   break;

    // case WEBAPP_EXH_PROFILE :
    //     conf = getWebAppExhConfig(actions, labels, adConfig, labels.profile[profile]);
    //     break;

    // case WEBAPP_EVT_PROFILE :
    //     conf = getWebAppEvtConfig(actions, labels, adConfig, labels.profile[profile]);
    //     break;

    // case WEBAPP_SPK_PROFILE :
    //     conf = getWebAppSpkConfig(actions, labels, adConfig, labels.profile[profile]);
    //     break;

    default:
      console.error("".concat(LOG_PREF, "Unsupported profile"), profile);
  }

  // Return config depending on page
  if (conf) {
    // Filter any empty entry
    Object.keys(conf).forEach(function (page) {
      conf[page] = conf[page].filter(menuItemConfig => menuItemConfig);
    });
    return conf[page] || conf.default;
  }
  // else undefined is returned
};