import config from 'app-customs/config/config';

import Swal from 'sweetalert2';

import { get as getLabels } from 'src/core/Lang';
import { iosHackScrollTo0 } from 'src/core/util/browser';

const LOG_PREF = '[showPromptModal] ';

// Possible values are: text, email, password, number, tel, range, textarea, select, radio, checkbox, file, url
const DEFAULT_INPUT_TYPE = 'text';

/**
 * @param  {object} opts such as:
 * {
 *   title: (optional) string
 *   text : (optional) string
 *   validateBtnLabel: (optional) string - default: labels.common.confirm
 *   cancelBtnLabel  : (optional) string - default: labels.common.cancel
 *
 *   anywayCb: (optional) function - callback executed is both case, before the other callbacks
 *   validateCb: function
 * }
 *
 * e.g
 * showPromptModal({
      title: 'Test',
      text: 'Tu nous files ton email ?',
      inputType: 'email',
      validateBtnLabel: 'Valider',
      cancelBtnLabel: 'Annuler',
      anywayCb: function() { console.log('executed anyway'); },
      validateCb: function(value) { console.log('validated value: '+value); },
   })
 */
function showPromptModal(opts) {
  if (!opts) {
    console.error(`${LOG_PREF}Missing prompt modal options`);
    return;
  }
  if (!opts.validateCb) {
    console.error(`${LOG_PREF}Missing validate callback`);
    return;
  }

  const labels = getLabels();

  const validateBtnLabel = opts.validateBtnLabel || labels.common.confirm;
  const cancelBtnLabel = opts.cancelBtnLabel || labels.common.cancel;

  // See sweetalert api documentation
  // https://sweetalert2.github.io/#usage

  Swal.fire({
    title: opts.title,
    text: opts.text,
    showCancelButton: true,
    confirmButtonText: validateBtnLabel,
    cancelButtonText: cancelBtnLabel,
    preConfirm: (value) => { Swal.resetValidationMessage(); Swal.enableButtons(); return opts.preConfirm(value).catch(err => Swal.showValidationMessage(err.message));},
    input: opts.inputType ? opts.inputType : DEFAULT_INPUT_TYPE,
    customClass: {
      container: 'dialog-container',
      popup: 'dialog-top',
      title: 'dialog-title',
    },
    heightAuto: false,
    showClass: {
      popup: 'swal2-show-override',
    },
  }).then((result) => {
    Swal.enableButtons();
    if (typeof opts.anywayCb === 'function') {
      opts.anywayCb();
    }
    if (result) {
      if(typeof validateCb === 'function'){
        opts.validateCb(result.value);
      }
    }

    window.setTimeout(iosHackScrollTo0, 100);
  }).catch(err => { if(err) {
    // Swal.showValidationMessage(err.message);
    Swal.enableButtons() }
  });
}

export default showPromptModal;

if (config.ENV) {
  global.showPromptModal = showPromptModal;
}
