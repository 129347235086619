import { DATA_TYPE_EVENT_CATEGORIES,
//DATA_TYPE_EXHIBITORS,
DATA_TYPE_EXHIBITOR_CATEGORIES,
//DATA_TYPE_EVENTS,
DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_SPEAKER_CATEGORIES, DATA_TYPE_STORE_CATEGORIES
//DATA_TYPE_GMAP_PLACES,
} from 'app-customs/config/dataConfig';
import config from 'app-customs/config/config';
import { LIST_PAGE_KEY,
//LIST_GROUPS_PAGE_KEY,
LOGIN_PAGE_KEY, USER_DATA_PAGE_KEY, MOBIGEO_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY, INBOX_PAGE_KEY, GOOGLE_MAP_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import * as Query from 'src/core/query/Query';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
import { CONFERENCES_TAB_KEY } from 'src/pages/user-data/UserDataContent';
import { isSessionValid } from 'src/core/login/LoginService';
import { get as getLabels } from 'src/core/Lang';
const LOG_PREF = '[homeConfig] ';
const {
  Online,
  Présentiel
} = require('./profiles');

// NB: used for ChooseProfile page too !
export const getStyle = () => ({
  //backgroundImage: `url(${getUrl('files/project/home/BG-home.png')})`,
  backgroundColor: 'white'
});
export const BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 2
};
export const MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export const GENERAL_TILE_OPTIONS = {
  height: 1,
  // rowspan
  width: 1 // colspan
};

// const tileBgGradient = 'linear-gradient(rgba(17, 17, 17, 0.65), rgba(85, 85, 85, 0.65))'

const getServiceIdByLumpOriginalId = (original_id, dataType) => {
  const items = Query.getTopCategories(dataType);
  let parentId;
  items && items.map(item => {
    if (item.original_id === original_id) {
      parentId = item.id;
    }
  });
  return parentId;
};
const createItemAd = (homebutton, lang, backgroundPosition) => createItem(
// Style
{
  container: {
    style: {},
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
homebutton["label_".concat(lang)],
// Action to perform on click
actions => {
  const url = homebutton["link_".concat(lang)];
  const {
    target
  } = homebutton;
  if (url) {
    openUrl(url, next => next && actions.linkClicked(url), target, true);
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getNewProductTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/participant.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.newproducts',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  inputs: [{
    dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getStoresTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/participants.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.stores',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  inputs: [{
    dataType: DATA_TYPE_STORE_CATEGORIES
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
function getParticipantsTile() {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }
  const isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Mise-en-relation.svg'), ")")
      }
    }
  },
  // Label (i18n)
  'home.miseEnRelation',
  // Page & props
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY, isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
}
const getEventsTile = profile => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/02-programme.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.agenda',
// Page & props
// LIST_GROUPS_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
// { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
LIST_PAGE_KEY, {
  inputs: [{
    id: getServiceIdByLumpOriginalId(profile === Présentiel ? '_BY_ONSITE_' : profile === Online && '_BY_ONLINE_', DATA_TYPE_EVENT_CATEGORIES),
    dataType: DATA_TYPE_EVENT_CATEGORIES
  }],
  contextualTitle: getLabels() && getLabels().data[DATA_TYPE_EVENT_CATEGORIES].title,
  hasGoToSynopticButton: true
},
// Tile options
GENERAL_TILE_OPTIONS);
const getQuizTile = () => createItem(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/picto_quiz.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.quiz',
// Page & props
actions => {
  const url = 'https://files.youslide.io/ecs/index.html ';
  if (url) {
    openUrl(url, actions.linkClicked(url, getLabels().home.quiz), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getMaletteTile = () => createItem(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/picto_malette.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.malette',
// Page & props
actions => {
  const url = 'https://congres.experts-comptables.com/exposition/recherche-partenaires.htm?Crit_RechExposant=x_ServicePlus%3ASP01';
  if (url) {
    openUrl(url, next => next && actions.linkClicked(url, getLabels().home.malette), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getDemoTile = () => createItem(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/picto_parcours.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.demo',
// Page & props
actions => {
  const url = 'https://congres.experts-comptables.com/exposition/recherche-partenaires.htm?Crit_RechExposant=x_ServicePlus%3ASP02';
  if (url) {
    openUrl(url, next => next && actions.linkClicked(url, getLabels().home.demo), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getCovoiturageTile = () => createItem(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/picto_covoiturage.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.covoiturage',
// Page & props
actions => {
  const url = 'https://congres.experts-comptables.com/informations-pratiques-rse/covoiturage.htm';
  if (url) {
    openUrl(url, next => next && actions.linkClicked(url, getLabels().home.covoiturage), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getServicesTile = () => createItem(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/05-infos.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.practicalInfo',
// Page & props
actions => {
  const url = 'https://congres.experts-comptables.com/informations-pratiques-rse/informations-pratiques.htm';
  if (url) {
    openUrl(url, next => next && actions.linkClicked(url, getLabels().home.practicalInfo), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getNewsTile = () => createItem(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/picto_congres.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.news',
// Page & props
actions => {
  const url = 'https://congres.experts-comptables.com';
  if (url) {
    openUrl(url, next => next && actions.linkClicked(url, getLabels().home.news), config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER);
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
function getConfTile() {
  if (config.LOGIN.FEATURE_ENABLED !== true) {
    return null;
  }
  return createItem(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/picto_conference.svg'), ")")
      }
    }
  },
  // Label (i18n)
  'home.conf',
  // Action to perform on click
  actions => {
    if (!isSessionValid()) {
      actions.navigate(LOGIN_PAGE_KEY);
    } else {
      actions.navigate(USER_DATA_PAGE_KEY, {
        tabKey: CONFERENCES_TAB_KEY,
        tabIndex: undefined
      });
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
}
const getExhibitorsTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/01-exposant.svg'), ")")
    }
    // className: 'home-icon-programme',
  }
},
// Label (i18n)
'home.exhibitors',
// Page & props
LIST_PAGE_KEY,
// { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
{
  inputs: [{
    dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
  }]
},
// Tile options
GENERAL_TILE_OPTIONS);
const getSpeakersTile = profile => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/04-intervenant.svg'), ")")
    }
    // className: 'home-icon-intervenants',
  }
},
// Label (i18n)
'home.speakers',
// Page & props
LIST_PAGE_KEY, {
  inputs: [{
    id: getServiceIdByLumpOriginalId(profile === Présentiel ? '_BY_ONSITE_' : profile === Online && '_BY_ONLINE_', DATA_TYPE_SPEAKER_CATEGORIES),
    dataType: DATA_TYPE_SPEAKER_CATEGORIES
  }],
  contextualTitle: getLabels() && getLabels().data[DATA_TYPE_SPEAKER_CATEGORIES].title
},
// Tile options
GENERAL_TILE_OPTIONS);
const getSocialMediaTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/06-rs.svg'), ")")
    }
  }
},
// Label (i18n)
'home.medias',
// Page & props
MEDIAS_PAGE_KEY, {
  controlMediaPageContent: {
    // hideTabMediaSocial: true,
    //mediaTabKey: 'twitter',
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getLogoCity = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/logo_InTheCity_Mar2022.svg'), ")")
    }
  }
},
// Label (i18n)
'',
// Page & props
'', {},
// Tile options
GENERAL_TILE_OPTIONS);
function getEbadgeTile(lang) {
  return createItem(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/badge.svg'), ")")
      }
    }
  },
  // Label (i18n)
  'home.ebadge',
  // Action to perform on click
  () => {
    if (openUrl(config.BADGE_LINK[lang], null, config.BADGE_LINK_TARGET, config.BADGE_LINK_OPENININAPPBROWSER)) {
      // actions.linkClicked(config.BADGE_LINK[lang]);
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS
  /* {
      height:
        window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation]
          ? 0.6
          : 0.9,
      width:
        window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 2 : 1,
    } */);
}
const getMobigeoTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/03-plan.svg'), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
'home.mobigeo',
// Page & props
MOBIGEO_PAGE_KEY, null,
// Tile options

GENERAL_TILE_OPTIONS);
const getGoogleMapTile = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/planParis.svg'), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
'home.googleMap',
// Page & props
GOOGLE_MAP_PAGE_KEY, null,
// Tile options

GENERAL_TILE_OPTIONS);
const getNotifications = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Notifications.svg'), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
'home.notifications',
// Page & props
INBOX_PAGE_KEY, null,
// Tile options
GENERAL_TILE_OPTIONS);
const getVideos = () => createItemRoute(
// Style
{
  container: {
    className: 'home-btn-text'
  },
  icon: {
    style: {
      backgroundImage: "url(".concat(getUrl('files/project/home/Youtube.svg'), ")")
    }
    // className: 'home-icon-plan',
  }
},
// Label (i18n)
'home.videos',
// Page & props
MEDIAS_PAGE_KEY, {
  controlMediaPageContent: {
    // hideTabMediaSocial: true,
    mediaTabKey: 'youtube' // to match index of social Media
  }
},
// Tile options
GENERAL_TILE_OPTIONS);
const getDefault = (lang, adConfig, orientation, isLoggedIn, profile) => {
  const hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {}
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      homeContent_Padding: 0,
      tiles: [
      // Programme
      getEventsTile(profile),
      // Exposants
      getExhibitorsTile(),
      // PLAN
      getMobigeoTile(),
      // conference
      getConfTile(),
      // Speakers
      getSpeakersTile(profile),
      // QUIZ EN LIGNE
      getQuizTile(),
      // MALETTE DEMATERIALISEE
      getMaletteTile(),
      // PARCOURS DEMO
      getDemoTile(),
      // COVOITURAGE
      getCovoiturageTile(),
      // INFOS PRATIQUES
      getServicesTile(),
      // Social Medias
      getSocialMediaTile(),
      // Mise en relation
      // getParticipantsTile(),

      // SITE DU CONGRES Actualites
      getNewsTile()

      // Badges
      //getEbadgeTile(lang),

      // getLogoCity(),

      // getStoresTile(),

      // isCordovaContext() && getGoogleMapTile(),

      // Notifications
      // getNotifications(),

      // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
const getOnline = (lang, adConfig, orientation, isLoggedIn, profile) => {
  const hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox', 'contactScan']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [
      // Exposants
      //getExhibitorsTile(),

      // Programme
      getEventsTile(profile),
      // Speakers
      getSpeakersTile(profile),
      // PLAN
      getMobigeoTile(),
      // Mise en relation
      getParticipantsTile(),
      // INFOS PRATIQUES
      getServicesTile(), getStoresTile(), getGoogleMapTile(),
      // Notifications
      getNotifications(),
      // Social Medias
      getSocialMediaTile(),
      // Videos
      getVideos()

      // INTERACTIVITE ATELIERS
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
const getPrésentiel = (lang, adConfig, orientation, isLoggedIn, profile) => {
  const hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox', 'contactScan']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [
      // Exposants
      getExhibitorsTile(),
      // Programme
      getEventsTile(profile),
      // Speakers
      getSpeakersTile(profile),
      // PLAN
      getMobigeoTile(),
      // Mise en relation
      getParticipantsTile(),
      // INFOS PRATIQUES
      getServicesTile(), getStoresTile(), getGoogleMapTile(),
      // Notifications
      getNotifications(),
      // Social Medias
      getSocialMediaTile(),
      // Videos
      getVideos()

      // INTERACTIVITE ATELIERS
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */

export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  switch (profile) {
    // PRO
    /* case Présentiel:
      return getPrésentiel(lang, adConfig, orientation, isLoggedIn);
    // GRAND PUBLIC
    case Online:
      return getOnline(lang, adConfig, orientation, isLoggedIn); */
    case Online:
    case Présentiel:
      return getDefault(lang, adConfig, orientation, isLoggedIn, profile);
    default:
      if (!profile) {
        console.warn(LOG_PREF + 'No profile set yet');
      } else {
        console.error(LOG_PREF + 'Unsupported profile: ' + profile);
      }
  }
}